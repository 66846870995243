import {FontAwesomeIcon} from "@fortawesome/vue-fontawesome";

import analysisCard from "../ToolsCard.vue";

import Button from "@/components/button";
import Input from "@/components/input";
import Table from "@/components/table";
import Tab from "@/components/tab";
import NeoTabsSwitch from "@/components/tabs-switch";
import Loader from "@/components/loader";
import DropdownAnalysis from "@/components/dropdown-analysis";
import VueMultiselect from "@/components/vue-multiselect";
// import "vue-multiselect/dist/vue-multiselect.min.css";
import "@/assets/css/multiselect.scss";
import axios from "../../../../axios/osint.js";
import SaasAxios from "@/axios";
import {get} from "lodash";
import neoSwitch from "@/components/toggle-switch";
import {getMonitoring} from "@/utils/functions";
import {monitoringSearchData, getBackgroundStatus, getResultsID} from "@/utils/monitoringTools.js";
import toolsHelper from "@/mixins/toolsHelper";
import {setUsernamesDropdownData, convertToCSV, csvFileName} from "../../../../utils/functions";
import {mapActions, mapGetters, mapMutations} from "vuex";
import NeoAnalyse from "@/components/analyse";
import JsonCSV from "vue-json-csv";
import {DateTime} from "luxon";
import {startCase} from "lodash";

export default {
    name: "neo-skype",
    mixins: [toolsHelper],
    components: {
        "neo-input": Input,
        "neo-button": Button,
        "neo-table": Table,
        "neo-tab": Tab,
        "neo-tabs-switch": NeoTabsSwitch,
        "font-awesome-icon": FontAwesomeIcon,
        "neo-loader": Loader,
        "neo-dropdown-analysis": DropdownAnalysis,
        VueMultiselect,
        "analysis-card": analysisCard,
        "download-csv": JsonCSV,
        NeoAnalyse,
        neoSwitch,
    },
    props: {
        toolData: {
            type: Array,
            default: () => [],
        },
        tool: {
            type: String,
            default: "",
        },
        toolSearchQueries: Array,
    },
    data() {
        return {
            inputValue: {
                text: "Email",
                value: "Email",
            },
            inputOptions: [
                {
                    group: "Field Type",
                    categories: [
                        {
                            text: "Email",
                            value: "Email",
                        },
                        {
                            text: "Username",
                            value: "Username",
                        },
                        {
                            text: "Name",
                            value: "Name",
                        },
                        {
                            text: "Phone Number",
                            value: "Phone",
                        },
                    ],
                },
            ],
            loaderController: {
                skype: {
                    loading: false,
                    success: false,
                    filterLoading: false,
                    filterSuccess: false,
                },
            },
            errorMessage: "",
            alertMessage: {
                success: null,
                error: null,
            },
            case_id: "",
            allSkype: [],
            csvData: {
                skype: [],
            },
            filters: {},
            headers: [
                {
                    text: "",
                    value: "details",
                },
            ],
            itemKey: "username",
            searchCaseFilter: "",
            selectable: false,
            hasAction: false,
            searchKeyword: "",
            selectedTab: "details",
            selectedItem: null,
            emailList: [],
            userNameList: [],
            nameList: [],
            phoneList: [],
            triggerRoute: true,
            showResult: false,
            selectVal: "",
            searchClicked: false,
            tabName: "",
            isLoading: false,
            request_id: "",
        };
    },
    watch: {
        // allSkype(newVal, prevVal) {
        //     if (newVal) {
        //         this.$emit("searchResult", { data: newVal, source: "skype", searchType: "skype_query" });
        //     }
        // },
        "$route.query"(to, from) {
            if (this.triggerRoute) {
                this.redirectToQuery();
            }
        },

        csvFields() {
            return ["username", "about", "displayname", "profile_image", "avatarUrl", "city", "country", "birthday"];
        },

        // selectedItem: {
        //     handler(val) {
        //         this.showResult = false;
        //     },
        //     deep: true,
        // },
    },
    async mounted() {
        this.case_id = this.$route.params.id;
        this.tabName = this.$route.name;
        const dataRes = this.getToolsData(this.tabName, "skype-tool");
        if (dataRes) this.redirectToQuery(dataRes);
        this.getToolData();
    },
    beforeDestroy() {
        this.setToolsData(this.tabName, "skype-tool");
    },
    methods: {
        ...mapMutations(["SET_ALL_TOOLS_DATA", "SET_ALL_MONITORING_DATA"]),
        ...mapActions(["getAllCaseUsernames", "getAllCaseEmails"]),

        async getToolData() {
            this.emailList = await this.getDropdownData("email");
            this.usernameList = await this.getDropdownData("username");
            this.nameList = await this.getDropdownData("name");
            this.phoneList = await this.getDropdownData("phone");
        },

        async getDropdownData(field) {
            let data = this.toolData.filter((user) => user.val_type === field);
            return data;
        },

        getOptionsList(list) {
            return [
                {
                    group: list === "phoneList" ? "Phone Number" : list.split("List")[0],
                    categories: this[list],
                },
            ];
        },

        handleChecked(data) {
            let allChecked = false;
            this.$emit("updateToolData", "skype", [...this.emailList, ...this.nameList, ...this.usernameList, ...this.phoneList], data);
            allChecked = [...this.emailList, ...this.usernameList, ...this.nameList, ...this.phoneList]?.length ? ([...this.emailList, ...this.usernameList, ...this.nameList, ...this.phoneList].findIndex((n) => !n.visited) > -1 ? false : true) : false;

            this.$emit("handleChecked", allChecked);
        },

        convertToCSV(arr) {
            return convertToCSV(arr);
        },

        csvFileName(query, section) {
            return csvFileName(this.tool, query, section);
        },

        startLoader(section) {
            this.loaderController[section].success = false;
            this.loaderController[section].loading = true;
        },
        successLoader(section) {
            this.loaderController[section].success = true;
            this.loaderController[section].loading = false;
        },
        resetLoader(section) {
            this.loaderController[section].success = false;
            this.loaderController[section].loading = false;
        },

        getTime(item) {
            let date = new Date(item.birthday);
            return date && item.birthday ? date.getDate() + "/" + (parseInt(date.getMonth(), 10) + 1) + "/" + date.getFullYear() : "";
        },

        updateFieldType() {
            this.selectedItem = null;
            this.searchKeyword = "";
            this.selectVal = "";
        },

        setInput() {
            this.searchKeyword = this.selectedItem?.value;
            // this.search();
        },

        setTheCustomInput(value) {
            this.searchKeyword = value;
            this.selectedItem = {
                value: value.trim(),
            };
            // this.search();
        },

        manualSearch() {
            this.triggerRoute = false;
            const emailRegex = /^(?:[A-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[A-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9]{2,}(?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])$/i;
            if (this.inputValue.value == "Email" && emailRegex.test(this.searchKeyword.trim())) this.search();
            else if (this.inputValue.value == "Email" && !emailRegex.test(this.searchKeyword.trim())) this.$toast.error("Please enter a valid input");
            else if (this.inputValue.value === "Phone" && this.validatePhone) this.search();
            else if (this.inputValue.value === "Phone" && !this.validatePhone) {
                this.$toast.error("Please provide a valid phone number with country code");
            } else this.search();
        },

        async search() {
            if (this.searchKeyword) {
                this.searchClicked = true;
                // IN MONITORING TAB FETCH MONITORING QUERY RESULTS
                if (this.isMonitoringTab) {
                    await this.monitoringSearch();
                    return;
                }
                await this.getSkype();
                await this.setSearchedSkypeUser(this.searchKeyword);
                this.SET_ALL_TOOLS_DATA({...this._data, "tool-name": "skype-tool"});
                this.triggerRoute = true;
            } else {
                this.$toast.error("Please enter a valid input");
            }
        },
        async setSearchedSkypeUser(searchKeyword) {
            let val_type = this.selectedItem?.val_type;

            if (!val_type) {
                if (this.inputValue.value == "Email") {
                    val_type = "email";
                } else if (this.inputValue.value == "Phone") {
                    val_type = "phone";
                } else if (this.inputValue.value == "Username") {
                    val_type = "username";
                } else if (this.inputValue.value == "Name") {
                    val_type = "name";
                }
            }
            const data = {
                value: searchKeyword,
                platform: "skype",
                val_type,
                visited: true,
            };
            let url = `research-methodology/${this.case_id}/tools/profiles`;
            let isSuccess = await setUsernamesDropdownData(url, data);
            // if (isSuccess) {
            let type = val_type;
            let dataItem = this[`${type}List`].find((dataItem) => {
                return dataItem.value === searchKeyword;
            });
            if (dataItem) {
                dataItem["visited"] = true;
                this.handleChecked(data);
            } else if (data?.value) {
                this[`${type}List`].push(data);
                this.$emit("addCustomData", data);
            }
            // }
        },
        async getSkype(isNext) {
            this.showResult = false;
            this.allSkype = [];
            this.csvData = {
                skype: [],
            };
            this.startLoader("skype");
            let data = {};
            let url = "/tools/skype";
            let val_type = "";
            if (this.inputValue.value == "Email") {
                val_type = "email";
            } else if (this.inputValue.value == "Phone") {
                val_type = "phone";
            } else if (this.inputValue.value == "Username") {
                val_type = "username";
            } else if (this.inputValue.value == "Name") {
                val_type = "query";
            }
            data = {
                [val_type]: val_type === "email" ? this.searchKeyword.trim() : this.searchKeyword,
            };
            this.$emit("searched", {data: {[val_type]: this.searchKeyword}, source: "skype", searchType: "skype_query"});
            await axios
                .post(url, data, {
                    headers: {
                        "x-tool-name": this.$route.params.toolName,
                        "x-visited": true,
                    },
                })
                .then((response) => {
                    if (response && response.data && response.data.data?.length) {
                        this.allSkype = response.data.data;
                        this.csvData.skype = this.convertToCSV(this.allSkype);
                        this.SET_ALL_TOOLS_DATA({...this._data, "tool-name": "skype-tool"});
                    } else if (response) {
                        this.$toast.error("No data found.");
                    }
                    this.request_id = response?.data?.query_id;
                    this.redirectTo();
                    this.$emit("searchResult", {data: this.allSkype, source: "skype", searchType: "skype_query"});

                    this.showResult = true;
                    this.resetLoader("skype");
                })
                .catch((error) => {
                    this.resetLoader("skype");
                    let message = "Please enter a valid input";
                    if (error.message === "Request failed with status code 424") message = "Please try again after some time";
                    this.$toast.error(message);
                });
        },
        redirectTo() {
            let query = {};
            if (this.request_id)
                query = {
                    query_id: this.request_id,
                };
            this.$router.push({path: this.$route.path, query}).catch((err) => {});
        },
        redirectToQuery(dataRes) {
            //redirect to same query
            if (this.$route?.query?.query_id) {
                let {query_id} = this.$route.query;
                let selected_query = this.toolSearchQueries?.find((el) => el._id === query_id);

                this.searchKeyword = Object.values(selected_query?.query)[0];
                this.selectedItem = {
                    value: Object.values(selected_query?.query)[0],
                };
                this.selectVal = Object.values(selected_query?.query)[0];
                let text_val = startCase(Object.keys(selected_query?.query)[0]) == "Query" ? "Name" : startCase(Object.keys(selected_query?.query)[0]);
                this.inputValue = {
                    text: text_val === "Phone" ? "Phone Number" : text_val,
                    value: text_val,
                };
                if (!dataRes) this.search();
            } else {
                this.redirectTo();
            }
        },
        onSelect({value}) {
            this.selectVal = value;
            this.$refs["analysis-tool-input"].search = value;
        },

        close() {
            this.selectedItem = {
                value: this.selectVal,
            };
            this.searchKeyword = this.selectVal;
            this.$refs["analysis-tool-input"].search = this.selectVal;
        },
        handleChange(event) {
            if (event != this.selectVal) {
                this.showResult = false;
                this.searchClicked = false;
            }
            this.selectVal = event;
        },
        onSelectFieldType() {
            this.showResult = false;
            this.selectedItem = null;
            this.$refs["analysis-tool-input"].search = "";
        },
        getMonitoring,
        getBackgroundStatus,
        getResultsID,

        async monitoringSearch() {
            let sources = ["skype-tool"];
            this.isLoading = true;
            let values = await monitoringSearchData(this.case_id, sources, this.$route.params.toolName, this.getMonitoringDateFilter, this.getSelectedToolQuery);
            this.allSkype = values;
            this.csvData.skype = this.convertToCSV(this.allSkype);
            this.showResult = true;
            this.isLoading = false;
        },

        async handleMonitoring(index) {
            // event.stopPropagation();
            let data;
            if (index >= 0) data = this.allSkype[index];
            this.allSkype[index].monitoring_status = !this.allSkype[index].monitoring_status;
            this.$forceUpdate();
            await SaasAxios.post("/data-monitoring", {
                product_name: this.getProduct.name,
                product_id: this.getProduct.key,
                case_id: this.$route.params.id,
                query: this.getSelectedToolQuery,
                tool: this.$route.params.toolName,
                frequency: 7,
                monitoring_type: "card",
                inherit_product_settings: false,
                inherit_product_id: "",
                card_id: data.doc_id,
                card_source: this.$route.params.toolName,
                status: data.monitoring_status ? "enabled" : "disabled",
            });
        },

        async handleDone({target}, index) {
            let data;
            if (index >= 0) data = this.allSkype[index];
            this.allSkype[index].changes_viewed = !this.allSkype[index].changes_viewed;
            this.$forceUpdate();
            let sources = ["skype-tool"];
            await SaasAxios.post("/data-monitoring/mark-monitered-data-visited", {
                sources,
                doc_id: data.monitoring_id,
                visited: target.checked,
            });
        },

        async handleMarkAsAllDone(event) {
            this.allSkype.forEach((result, index) => {
                this.allSkype[index].changes_viewed = event.target.checked;
            });
            this.$forceUpdate();
            let sources = ["skype-tool"];
            await SaasAxios.post("/data-monitoring/mark-monitered-data-visited/query/all", {
                sources: sources,
                mark_all_as_visited: event.target.checked,
                query_id: this.getSelectedToolQuery.query_id,
            });
        },
        getFormattedTime(ts) {
            let date = DateTime.fromISO(ts);
            return date.toFormat("dd MMM yyyy");
        },
    },
    computed: {
        ...mapGetters(["getProduct", "getSelectedToolQuery", "getMonitoringDateFilter", "getReadOnlyMode", "getResearchData", "getAllToolsData", "getAllMonitoringData", "getCaseUsernames", "getCaseEmails"]),

        fetchAllData() {
            if (this.getResearchData.personalDetails?.length || this.getResearchData.socialProfiles?.length) {
                this.getToolData();
            }
        },

        isMonitoringTab() {
            return this.$route.name === "monitoring";
        },

        // this.adverseResults
        checkMarkAllDone() {
            if (this.allSkype && this.allSkype.length) return this.allSkype.every((result) => result.changes_viewed);
            else return false;
        },

        validatePhone() {
            this.searchKeyword = this.searchKeyword.replaceAll(" ", "");
            this.searchKeyword = this.searchKeyword.substr(0, 1) + this.searchKeyword.substr(1).replaceAll("+", "");
            this.searchKeyword = this.searchKeyword.replace(/-|_|/gi, "");
            this.searchKeyword = this.searchKeyword.replaceAll("(", "");
            this.searchKeyword = this.searchKeyword.replaceAll(")", "");
            const phoneRegex = /^[+]{1}[0-9-]/;
            return phoneRegex.test(this.searchKeyword);
        },
    },
};
